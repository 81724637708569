import * as authenticationService from './authentication.service';
import { User } from '../../App';
import { NavigateFunction } from 'react-router-dom';
import React from "react";

interface RequestOptions extends RequestInit {
    headers?: {
        [key: string]: any;
        Authorization?: string;
    };
}

export async function handleResponse(
    response: Response,
    requestOptions: RequestOptions,
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
    navigate: NavigateFunction
): Promise<any> {
    if (!response.ok) {
        if ([401, 403].includes(response.status)) {
            const clonedResponse = response.clone();
            const body = await clonedResponse.json();

            if (body.detail && body.detail.toLowerCase().includes('token')) {
                return authenticationService.refreshToken(response.url, requestOptions, setUser, navigate);
            }
        }
    }
    return response.json();
}
