import React, {useCallback} from 'react';
interface MedLoginPopupProps {
    active: boolean;
    onPopupHide: (isVisible: boolean) => void;
}

const MedLoginPopup: React.FC<MedLoginPopupProps> = ({ active, onPopupHide }) => {
    const closePopup = useCallback(() => {
        onPopupHide(false);
    }, [onPopupHide]);
    return (
        <div className='med-popup' >
            <div className='popup-wrapper'>
                <h1 className='med-title'>Skontaktuj się z nami</h1>
                <span className='med-text'>Prowadzisz przychodnię weterynaryjną i chcesz pomóc właścicielom zwierząt zabezpieczyć pupile przed zgubą? Skontaktuj się z nami i rozpocznij współpracę z DOGid już dziś!</span>
                <a className='med-contact' href="tel:+48 914 222 222">Zadzwoń: +48 914 222 222</a>
                <a className='med-contact' href="mailto:biuro@dogid.pl" >Napisz: biuro@dogid.pl</a>
                <button className='med-close' onClick = {closePopup}></button>
            </div>
        </div>
    )
}


export default MedLoginPopup;
