import React, {useEffect, useRef, useState} from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const HCaptchaComponent: React.FC = () => {
    const hcaptchaRef = useRef<HCaptcha | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const onVerify = (token: string) => {
        setToken(token);
        console.log('hCaptcha Token:', token);
    };

    const onError = (err: string) => {
        setError(err);
        console.error('hCaptcha Error:', err);
    };

    const onExpire = () => {
        setToken(null);
        console.log('hCaptcha Token expired');
    };
    return (
        <div>
            <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ""} // Replace with your actual site key
                onVerify={onVerify}
                onError={onError}
                onExpire={onExpire}
                ref={hcaptchaRef}
            />
        </div>
    );
};

export default HCaptchaComponent;
