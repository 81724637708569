import { authHandler } from './authHandler';
import { handleResponse } from './handleResponse';
import React from 'react';
import { User } from '../../App';
import { NavigateFunction } from 'react-router-dom';

interface RequestOptions extends RequestInit {
    headers?: {
        [key: string]: any;
        Authorization?: string;
    };
}

interface TokenResponse {
    access?: string;
    detail?: string;
    [key: string]: any;
}

export const refreshToken = async (
    oldUrl: string,
    oldRequestOptions: RequestOptions,
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
    navigate: NavigateFunction
): Promise<any> => {
    const refreshToken = JSON.parse(window.localStorage.getItem('refresh') || 'null');

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
    };

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/token/refresh/`,
            requestOptions
        );
        const data: TokenResponse = await response.json();

        if (data.access) {
            localStorage.setItem('access', JSON.stringify(data.access));
            if (oldRequestOptions.headers) {
                const authorization = authHandler(setUser, navigate);
                if (authorization) {
                    oldRequestOptions.headers.Authorization = authorization;
                }
            }
            const newResponse = await fetch(oldUrl, oldRequestOptions);
            return handleResponse(newResponse, oldRequestOptions, setUser, navigate);
        } else if (data.detail === 'Token jest niepoprawny lub wygasł') {
            return logOut(setUser, navigate);
        }
        return data;
    } catch (error) {
        console.error('Error refreshing token:', error);
        return logOut(setUser, navigate);
    }
};

export const logOut = (
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
    navigate?: NavigateFunction
): void => {
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    setUser(null);
    if (navigate) {
        navigate('/');
    }else{
        window.location.replace("/")
    }
};
