import React from "react";
import RoutesPaths from "./RoutesPaths";

const HomePage = React.lazy(() => import("./components/pages/Home/Home"));
const DogProfile = React.lazy(() =>
	import("./components/pages/DogProfile/DogProfile")
);
const RegisterID = React.lazy(() =>
	import("./components/pages/RegisterID/RegisterID")
);
const Success = React.lazy(() =>
	import("./components/pages/Success/Success")
);
const Payment = React.lazy(() =>
	import("./components/pages/Payment/Payment")
);
const VetPage = React.lazy(() =>
	import("./components/pages/Vet/Vet")
);
const OwnerPage = React.lazy(() =>
	import("./components/pages/Owner/Owner")
);
const AboutPage = React.lazy(() =>
	import("./components/pages/About/About")
);
const Policy = React.lazy(() =>
	import("./components/pages/Policy/Policy")
);
const Rules = React.lazy(() =>
	import("./components/pages/Rules/Rules")
);
const MyData = React.lazy(() =>
	import("./components/pages/UserProfile/MyData/MyData")
);
const MyIDs = React.lazy(() =>
	import("./components/pages/UserProfile/MyIDs/MyIDs")
);
const Contact = React.lazy(() =>
	import("./components/pages/Contact/Contact")
);
const routerList = [
	{
		path: RoutesPaths.Home.path,
		component: <HomePage />,
	},
	{
		path: RoutesPaths.DogProfile.path,
		component: <DogProfile />,
	},
	{
		path: RoutesPaths.RegisterID.path,
		component: <RegisterID />,
	},
	{
		path: RoutesPaths.Success.path,
		component: <Success />,
	},
	{
		path: RoutesPaths.Payment.path,
		component: <Payment />,
	},
	{
		path: RoutesPaths.Vet.path,
		component: <VetPage />,
	},
	{
		path: RoutesPaths.Owner.path,
		component: <OwnerPage />,
	},
	{
		path: RoutesPaths.About.path,
		component: <AboutPage />,
	},
	{
		path: RoutesPaths.Policy.path,
		component: <Policy />,
	},
	{
		path: RoutesPaths.Rules.path,
		component: <Rules />,
	},
	{
		path: RoutesPaths.MyData.path,
		component: <MyData />,
	},
	{
		path: RoutesPaths.MyIDs.path,
		component: <MyIDs />,
	},
	{
		path: RoutesPaths.Contact.path,
		component: <Contact />,
	},
];

export default routerList;
