import React, { useEffect, useMemo, useState, createContext } from 'react';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import PageWrapper from './components/wrapper/PageWrapper';
import { createGlobalStyle } from 'styled-components';
import { routes } from './routes';
import { logOut } from './components/hooks/authentication.service';
import SliderMenu from './components/elements/SliderMenu/SliderMenu';
import './styles/styles.scss';

export interface User {
  access: string;
  refresh: string;
  user_id: number;
  user_profile_id: number;
  photographer_profile_id: number;
}

export interface Dog {
  // Uzupełnij odpowiednimi polami dla obiektu Dog
}

export interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

interface DogContextProps {
  dog: Dog | null;
  setDog: React.Dispatch<React.SetStateAction<Dog | null>>;
}
interface LoginContextProps {
  showLogin: boolean;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
}
interface RegisterContextProps {
  showRegister: boolean;
  setShowRegister: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserContext = React.createContext<UserContextProps | null>(null);
export const DogContext = createContext<DogContextProps | null>(null);
export const LoginContext = createContext<LoginContextProps | null>(null);
export const RegisterContext = createContext<RegisterContextProps | null>(null);

const GlobalStyles = createGlobalStyle`
  :root {
    --large: 1440px;
    --medium: 1024px;
    --small: 768px;
  }
  #root{
    position: relative;
    overflow-x: hidden;
  }
  html {
    font-size: 10px;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [dog, setDog] = useState<Dog | null>(null);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showRegister, setShowRegister] = useState<boolean>(false)

  const userValue = useMemo(() => ({ user, setUser }), [user]);
  const dogValue = useMemo(() => ({ dog, setDog }), [dog]);
  const loginValue = useMemo(() => ({ showLogin, setShowLogin }), [showLogin]);
  const registerValue = useMemo(() => ({ showRegister, setShowRegister }), [showRegister]);
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedRefresh = localStorage.getItem('refresh');
    const storedAccess = localStorage.getItem('access');

    if (storedUser && storedUser !== 'undefined') {
      const parsedUser = JSON.parse(storedUser);
      setUser({
        access: storedAccess ? JSON.parse(storedAccess) : '',
        refresh: storedRefresh ? JSON.parse(storedRefresh) : '',
        user_id: parsedUser.user_id,
        user_profile_id: parsedUser.user_profile_id,
        photographer_profile_id: parsedUser.photographer_profile_id,
      });
    } else if (storedRefresh && storedRefresh !== 'undefined') {
      const refreshToken = JSON.parse(storedRefresh);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      };

      fetch(`${process.env.REACT_APP_API_URL}/api/auth/token/refresh/`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.access) {
              localStorage.setItem('access', JSON.stringify(data.access));
              localStorage.setItem(
                  'user',
                  JSON.stringify({
                    user_id: data.user_id,
                    user_profile_id: data.user_profile_id,
                    photographer_profile_id: data.photographer_profile_id,
                  })
              );
              setUser({
                access: data.access,
                refresh: refreshToken,
                user_id: data.user_id,
                user_profile_id: data.user_profile_id,
                photographer_profile_id: data.photographer_profile_id,
              });
            } else if (data.detail === 'Token is invalid or expired') {
              logOut(setUser);
            }
          })
          .catch((error) => {
            console.error('Error refreshing token:', error);
            logOut(setUser);
          });
    }
  }, [setUser]);

  return (
      <div className="app">
        <GlobalStyles />
        <UserContext.Provider value={userValue}>
          <DogContext.Provider value={dogValue}>
          <LoginContext.Provider value={loginValue}>
          <RegisterContext.Provider value={registerValue}>
            <BrowserRouter>
              <PageWrapper>{routes}</PageWrapper>
              <SliderMenu />
            </BrowserRouter>
          </RegisterContext.Provider>
          </LoginContext.Provider>
          </DogContext.Provider>
        </UserContext.Provider>
      </div>
  );
};

export default App;
