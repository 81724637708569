import React, {useContext} from 'react';
import CloseIcon from './CloseIcon';
import handleCloseMenuSidebar from '../../helpers/handleCloseMenuSidebar';
import {LoginContext, RegisterContext, UserContext} from "../../../App";
import {logOut} from "../../hooks/authentication.service";
import {useNavigate} from "react-router-dom";

interface MenuItem {
    name: string;
    url: string;
}

const menuList: MenuItem[] = [
    { name: "Wyszukaj numer zawieszki", url: "/" },
    { name: "Dla właściciela", url: "/owner" },
    { name: "Dla weterynarza", url: "/vet" },
];

const SliderMenu: React.FC = () => {
    const {user, setUser} = useContext(UserContext) || {};
    const navigate = useNavigate()
    const {showLogin, setShowLogin} = useContext(LoginContext) || {};
    const {showRegister, setShowRegister} = useContext(RegisterContext) || {};
    const handleLogOut = () => {
        if(setUser){
            logOut(setUser, navigate)
            handleCloseMenuSidebar()
        }
    }
    const handleRegister = () => {
        if(setShowRegister){
            navigate("/");
            setShowRegister(true)
        }
        handleCloseMenuSidebar()
    }
    const handleLogIn = () => {
        if(setShowLogin){
            navigate("/");
            setShowLogin(true)
        }
        handleCloseMenuSidebar()
    }
    return (
        <div className='hamburger-menu-container' id="slider_menu">
            <div className='hamburger-menu-wrapper'>
                <div className='hamburger-menu-header'>
                    <span className='hamburger-header-logo'>
                        DOG<span className='hamburger-logo-highlight'>id</span>
                    </span>
                    <CloseIcon handleClose={handleCloseMenuSidebar}/>
                </div>
                <nav className='hamburger-menu-nav'>
                    <ul className='hamburger-menu-nav-list'>
                        {menuList.map((element, index) => (
                            <li className='hamburger-menu-nav-item' key={index}>
                                <a className='hamburger-menu-nav-item-link' href={element.url}>{element.name}</a>
                            </li>
                        ))}
                        <li className='hamburger-menu-nav-item'>
                            <a className='hamburger-menu-nav-item-link' href='/kontakt'>Kontakt</a>
                        </li>
                    </ul>
                </nav>
                {!user?.access ?
                    <>
                <span onClick={handleRegister} className='hamburger-menu-button register'>
                    Nowe konto
                </span>
                <span onClick={handleLogIn} className='hamburger-menu-button'>
                    Zaloguj się
                </span>
                </>
                    :
                        <>
                            <a href="/my-data" className='hamburger-menu-user-link'>
                                Moje konto
                            </a>
                            <a href="/my-ids" className='hamburger-menu-user-link'>
                                Moje identyfikatory
                            </a>
                            <span onClick={handleLogOut} className='hamburger-menu-user-logout'>
                                Wyloguj się
                            </span>
                        </>
                }
                <div className='found-dog-container'>
                    <span>Znalazłeś psa? Zadzwoń</span>
                    <a href='tel:914222222'>914 222 222</a>
                </div>
                <div className='rules-container'>
                    <a className='hamburger-menu-nav-item-link' href={'/regulamin'}>Regulamin</a>
                    <a className='hamburger-menu-nav-item-link' href={'/polityka'}>Polityka prywatności</a>
                </div>
            </div>
        </div>
    );
};

export default SliderMenu;
