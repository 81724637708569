import React from 'react'

// @ts-ignore
const CloseIcon = ({ handleClose }) => {
    return (
        <div className='hamburger-menu-close-icon-container' onClick={() => handleClose()}>
            <div className='hamburger-menu-close-icon'>
                <div className='hamburger-menu-close-line' />
                <div className='hamburger-menu-close-line' />
            </div>
        </div>

    )
}

export default CloseIcon
