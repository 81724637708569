import React, {useContext, useEffect, useState, ChangeEvent, FormEvent, Dispatch, SetStateAction} from "react";
import MedLoginPopup from "../../elements/MedComponent/MedLoginPopup";
import {UserContext} from "../../../App";
import {useNavigate} from "react-router-dom";
import close from "../../../assets/Login/close-icon.png";
import HCaptchaComponent from "../../elements/HCaptcha";
interface Inputs {
    email: string;
    password: string;
}

interface Errors {
    email: string;
    password: string;
    detail: string;
}
interface LoginProps {
	setShowLogin :Dispatch<SetStateAction<boolean>> | undefined;
	setShowRegister :Dispatch<SetStateAction<boolean>> | undefined;
}
const Login: React.FC<LoginProps> = ({ setShowLogin, setShowRegister})  => {
    // @ts-ignore
    const {setUser, user} = useContext(UserContext);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState<Inputs>({email: "", password: ""});
    const [errors, setErrors] = useState<Errors>({email: "", password: "", detail: ""});
    const [activeMedPopup, setActiveMedPopup] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("DogID | Login");

    useEffect(() => {
        document.title = title;
    }, [title]);

    // useEffect(() => {
    //     if (user?.access) {
    //         window.location.replace("/owner-profile");
    //     }
    // }, [user]);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = e.target;
        setErrors((prevState) => ({...prevState, [name]: ""}));
        setInputs((prevState) => ({...prevState, [name]: value}));
    };

    const handleLogIn = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setErrors({email: "", password: "", detail: ""});

        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let error = false;

        if (inputs.email.trim() === "") {
            setErrors((prevState) => ({...prevState, email: "Proszę wpisać email"}));
            error = true;
        } else if (!emailRegex.test(inputs.email)) {
            setErrors((prevState) => ({...prevState, email: "Proszę wpisać poprawny email"}));
            error = true;
        }

        if (inputs.password.trim() === "") {
            setErrors((prevState) => ({...prevState, password: "Proszę wpisać hasło"}));
            error = true;
        }

        if (!error) {
            const requestOptions: RequestInit = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(inputs),
            };

            fetch(`${process.env.REACT_APP_API_URL}/api/auth/token/`, requestOptions)
                .then((response) => (response.ok ? response.json() : response.json().then((data) => Promise.reject(data))))
                .then((response) => {
                    setUser(response);
                    localStorage.setItem("refresh", JSON.stringify(response.refresh));
                    localStorage.setItem("access", JSON.stringify(response.access));
                    window.location.replace("/my-ids");
                    if (setShowLogin) {
                        setShowLogin(false);
                    }
                    if (setShowRegister) {
                        setShowRegister(false)
                    }
                })
                .catch((errorResponse) => {
                    setErrors((prevState) => ({...prevState, detail: errorResponse.detail}));
                });
        }
    };

    return (
        <div className='login-container'>
			<div className='close-container'>
				<img src={close} alt="close icon" onClick={()=> setShowLogin && setShowLogin(false)}/>
			</div>
            <div className='login-column'>
                <h1 className='login-title'>Witaj ponownie!</h1>
                <h1 className='login-title-mobile'>Witaj! 👋</h1>
                <form className='global-form' onSubmit={handleLogIn}>
                    <input className={`global-input ${errors.email && 'error'}`}
                           placeholder="Nazwa użytkownika/email"
                           type="email"
                           name="email"
                           onChange={handleChangeInput}
                    />
                    {errors.email && <span className='global-error'>{errors.email}</span>}
                    <input
                        className={`global-input ${errors.password && 'error'}`}
                        placeholder="Hasło"
                        type="password"
                        name="password"
                        onChange={handleChangeInput}
                    />
                    {errors.password && <span className='global-error'>{errors.password}</span>}
                    {errors.detail && <span className='global-error'>{errors.detail}</span>}
                    <HCaptchaComponent />
					<button className='login-button' type='submit'>
						Zaloguj się
					</button>
					<span className='no-account-yet'>
						Nie masz jeszcze konta?
						<span
							className='register-button'
							onClick={()=>{
                                if (setShowRegister) {
                                    setShowRegister(true);
                                }
                                if (setShowLogin) {
                                    setShowLogin(false);
                                }
							}}
						>
							{' '}Utwórz
						</span>
					</span>
                    <a className='login-problem' href='#'>Problem z logowaniem?</a>
                </form>
            </div>
            <MedLoginPopup active={activeMedPopup} onPopupHide={setActiveMedPopup}/>
        </div>
    );
};

export default Login;
