import React, { Suspense } from 'react';

interface RouteWrapperProps {
  children: React.ReactNode;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({ children }) => {
  return <Suspense fallback={<p>Loading</p>}>{children}</Suspense>;
};

export default RouteWrapper;
