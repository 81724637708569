import React from 'react'
import handleCloseMenuSidebar from '../../helpers/handleCloseMenuSidebar'

const HamburgerIcon = () => {



    return (
        <>
            <div id='empty_backgound' className='empty_background' onClick={() => handleCloseMenuSidebar()} />
            <div className='header-hamburger-icon' onClick={handleCloseMenuSidebar} >
                <span className='header-hamburger-element'></span>
                <span className='header-hamburger-element center'></span>
                <span className='header-hamburger-element'></span>
            </div>
        </>


    )
}

export default HamburgerIcon
