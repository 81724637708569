const handleCloseMenuSidebar = (): void => {
    const pageWrapper = document.getElementById('pageWrapper');
    const sliderMenu = document.getElementById('slider_menu');
    const emptyBackground = document.getElementById('empty_backgound');

    if (pageWrapper) {
        pageWrapper.classList.toggle('pageWrapper_move');
    }

    if (sliderMenu) {
        sliderMenu.classList.toggle('active_slider');
    }

    if (emptyBackground) {
        emptyBackground.classList.toggle('empty_background_active');
    }

    if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'unset';
    } else {
        document.body.style.overflow = 'hidden';
    }
};

export default handleCloseMenuSidebar;
