export const directionalNumbers = [
    "+48",  // Poland
    "+355", // Albania
    "+376", // Andorra
    "+43",  // Austria
    "+375", // Belarus
    "+32",  // Belgium
    "+387", // Bosnia and Herzegovina
    "+359", // Bulgaria
    "+385", // Croatia
    "+357", // Cyprus
    "+420", // Czech Republic
    "+45",  // Denmark
    "+372", // Estonia
    "+358", // Finland
    "+33",  // France
    "+49",  // Germany
    "+30",  // Greece
    "+36",  // Hungary
    "+354", // Iceland
    "+353", // Ireland
    "+39",  // Italy
    "+371", // Latvia
    "+423", // Liechtenstein
    "+370", // Lithuania
    "+352", // Luxembourg
    "+389", // North Macedonia
    "+356", // Malta
    "+377", // Monaco
    "+382", // Montenegro
    "+31",  // Netherlands
    "+47",  // Norway
    "+351", // Portugal
    "+40",  // Romania
    "+7",   // Russia
    "+378", // San Marino
    "+381", // Serbia
    "+421", // Slovakia
    "+386", // Slovenia
    "+34",  // Spain
    "+46",  // Sweden
    "+41",  // Switzerland
    "+90",  // Turkey
    "+380", // Ukraine
    "+44",  // United Kingdom
    "+1"    // United States (USA)
];
