import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/settings/reset.css';
import './components/settings/authCodeInput.css';
import './components/settings/sliderAnimation.css';
import App from './App';

const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error("Root element with id 'root' not found.");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
