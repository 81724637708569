import React, { useContext } from 'react';
import Footer from '../elements/Footer/Footer';
import Header from '../elements/Header/Header';
import { UserContext } from '../../App';
import { useLocation } from 'react-router-dom';

interface PageWrapperProps {
    children: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
    const { user } = useContext(UserContext) || {}; // Typowanie `UserContext` zakłada możliwość zwrócenia null
    const route = useLocation();

    console.log(route);

    return (
        <div id="pageWrapper">
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default PageWrapper;
