import React, {useContext, useEffect, useState} from 'react';
import {LoginContext, RegisterContext, UserContext} from '../../../App';
import {useLocation, useNavigate} from 'react-router-dom';
import HamburgerIcon from './HamburgerIcon';
import PhoneIcon from '../../../assets/Header/phone-icon.png'
import account from '../../../assets/Header/account-icon.png'
import Login from "../../pages/Login/Login";
import Register from "../../pages/Register/Register";
import {logOut} from "../../hooks/authentication.service";

enum DisplayEnum {
    NONE = 'none',
    STICKY = 'sticky',
    ABSOLUTE = 'absolute',
}

const Header: React.FC = () => {
    const {user, setUser} = useContext(UserContext) || {};
    const {showLogin, setShowLogin} = useContext(LoginContext) || {};
    const {showRegister, setShowRegister} = useContext(RegisterContext) || {};
    const [stickyHeader, setStickyHeader] = useState<DisplayEnum>(DisplayEnum.ABSOLUTE);
    const [showDropdown, setShowDropdown] = useState(false);;
;
    useEffect(() => {
        const handleScroll = (): void => {
            const scrolledDistance = window.pageYOffset;
            if (scrolledDistance < 82) {
                setStickyHeader(DisplayEnum.ABSOLUTE);
            } else if (scrolledDistance > 80 && scrolledDistance < 100) {
                setStickyHeader(DisplayEnum.NONE);
            } else if (scrolledDistance > 100) {
                setStickyHeader(DisplayEnum.STICKY);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const navigate = useNavigate()
    const handleLogOut = () => {
        if(setUser){
            logOut(setUser, navigate)
        }
    }
    // @ts-ignore
    return (
        <>
            {
                showLogin &&
                <div className='login-component'>
                    <Login setShowLogin={setShowLogin} setShowRegister={setShowRegister} />
                </div>
            }
            {
                showRegister &&
                <div className='register-component'>
                    <Register setShowLogin={setShowLogin} setShowRegister={setShowRegister}/>
                </div>
            }
            <div
                className={`header-container ${stickyHeader === DisplayEnum.NONE && 'display'} ${stickyHeader === DisplayEnum.STICKY && 'primary'}`}>
                <nav className='header-wrapper'>
                       <span className='header-logo-mobile' onClick={() => navigate('/')}>
                                DOG<span className='logo-highlight'>id</span>
                            </span>
                    <ul className='header-navigation'>
                        <li className='list-element'>
                            <span className='header-logo' onClick={() => navigate('/')}>
                                DOG<span className='logo-highlight'>id</span>
                            </span>
                        </li>
                        <li className='list-element'>
                            <a className='for-owner' href='/owner'>
                                Dla właściciela
                            </a>
                        </li>
                        <li className='list-element'>
                            <a className='for-vet' href='/vet'>
                                Dla weterynarza
                            </a>
                        </li>
                        <li className='list-element'>
                            <a className='help-line' href='tel:914222222'>
                                <img src={PhoneIcon} alt='phone icon'/>
                                914 222 222
                            </a>
                        </li>
                        <li className='list-element' onMouseEnter={() => setShowDropdown(true)}>
                            <div className='account-container'>
                                {!user?.access ? (
                                    <>
                                        <span className='link' onClick={() => setShowRegister && setShowRegister(true)}>Utwórz konto</span>
                                        <span className='link' onClick={() => setShowLogin && setShowLogin(true)}>Zaloguj się</span>
                                    </>
                                ) : (
                                    <div
                                        className='dropdown-menu-container'
                                        onMouseEnter={() => setShowDropdown(true)}
                                        onMouseLeave={() => setShowDropdown(false)}
                                    >
                                        <img
                                            src={account}
                                            alt='account icon'
                                        />
                                        {
                                            showDropdown ?
                                                <div
                                                    className='dropdown-menu'
                                                    style={{top: '23px'}}  // Adjust this as needed based on your layout
                                                >
                                                    <a href='/my-data' className='dropdown-menu-el'>Moje konto</a>
                                                    <a href='/my-ids' className='dropdown-menu-el'>Moje
                                                        identyfikatory</a>
                                                    <a href='/help' className='dropdown-menu-el'>Pomoc</a>
                                                    <a href='/kontakt' className='dropdown-menu-el'>Kontakt</a>
                                                    <span onClick={handleLogOut}
                                                          className='dropdown-menu-el'>Wyloguj</span>
                                                </div>
                                                : null
                                        }
                                    </div>

                                )}
                            </div>
                        </li>
                    </ul>
                    <HamburgerIcon/>
                </nav>
            </div>
        </>
    );
};

export default Header;
