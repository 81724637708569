import * as authenticationService from './authentication.service';
import React, {Dispatch, SetStateAction} from "react";
import {User} from "../../App";
import {NavigateFunction} from "react-router-dom";

export function authHandler(
    setUser: React.Dispatch<React.SetStateAction<User | null>>,
    navigate: NavigateFunction
): string | undefined {
    const accessToken = localStorage.getItem('access');
    if (accessToken && accessToken !== 'null' && accessToken !== 'undefined') {
        return `Bearer ${JSON.parse(accessToken)}`;
    } else {
        authenticationService.logOut(setUser, navigate);
        return undefined; // Explicitly return undefined to avoid returning void.
    }
}
